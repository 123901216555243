.blockDealInfo {
  padding: calc(20vw * 0.05208333) 0;
  position: relative;
}
.blockDealInfo > img {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.blockDealInfo-detail {
  padding: calc(20vw * 0.05208333) calc(260vw * 0.05208333);
}
.blockDealInfo-detail_title {
  text-align: center;
  color: #212326;
  font-size: calc(28vw * 0.05208333);
  font-weight: 600;
  margin: 0 0 calc(49vw * 0.05208333);
  position: relative;
}
.blockDealInfo-detail_title > img {
  width: calc(46vw * 0.05208333);
  -webkit-transform: translate(calc(-10vw * 0.05208333), 0);
          transform: translate(calc(-10vw * 0.05208333), 0);
  z-index: -1;
  position: relative;
}
.blockDealInfo-detail_title .title-query {
  position: absolute;
  bottom: calc(0vw * 0.05208333);
  right: 0;
  text-align: left;
}
.blockDealInfo-detail_info {
  background: #ffffff;
  border-radius: calc(20vw * 0.05208333);
  box-shadow: calc(1vw * 0.05208333) calc(20vw * 0.05208333) calc(20vw * 0.05208333) 0 #f2f2f2;
  border: calc(1vw * 0.05208333) solid #efefef;
  position: relative;
  margin: 0 0 calc(40vw * 0.05208333);
}
.blockDealInfo-detail_info > img {
  position: absolute;
  height: 95%;
  right: 0;
  border-radius: calc(20vw * 0.05208333);
}
.blockDealInfo-detail_info .img-ellipse_left {
  position: absolute;
  left: calc(-150vw * 0.05208333);
  width: calc(400vw * 0.05208333);
  height: auto;
  top: calc(200vw * 0.05208333);
}
.blockDealInfo-detail_info .img-ellipse_right {
  position: absolute;
  right: calc(-150vw * 0.05208333);
  width: calc(512vw * 0.05208333);
  height: calc(512vw * 0.05208333);
  top: calc(200vw * 0.05208333);
}
.blockDealInfo-detail_info .info-desc {
  position: relative;
  flex-wrap: wrap;
}
.blockDealInfo-detail_info .info-desc_label {
  width: 100%;
}
.blockDealInfo-detail_info .info-desc_label .label-title {
  width: calc(120vw * 0.05208333);
  padding: calc(40vw * 0.05208333) 0 0 calc(30vw * 0.05208333);
  font-size: calc(20vw * 0.05208333);
  font-weight: 600;
}
.blockDealInfo-detail_info .info-desc_value {
  padding: 0 calc(30vw * 0.05208333) calc(40vw * 0.05208333);
}
.blockDealInfo-detail_info .info-desc_value .desc-status {
  color: #3eba59;
}
.blockDealInfo-detail_info .info-desc_value .desc-slot {
  display: inline-block;
  color: #959eb6;
}
.blockDealInfo-detail_info .info-desc_value .desc-slot > span {
  padding: 0 calc(10vw * 0.05208333) 0 0;
}
.blockDealInfo-detail_info .info-desc_value .desc-preview {
  flex: 1 1;
  text-align: right;
  padding: 0 calc(10vw * 0.05208333);
}
.blockDealInfo-detail_info .info-desc .flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.config-preview_slot {
  padding: calc(20vw * 0.05208333) 0 0;
  color: #959eb6;
}
