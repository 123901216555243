.network {
  padding: calc(20vw * 0.05208333) 0 0;
}
.network > img {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.network-detail {
  padding: calc(20vw * 0.05208333) calc(260vw * 0.05208333);
  position: relative;
}
.network-detail > img {
  width: calc(300vw * 0.05208333);
  position: absolute;
  top: calc(300vw * 0.05208333);
  left: 0;
}
.network-detail_title {
  text-align: center;
  font-size: calc(23vw * 0.05208333);
  font-weight: 600;
  margin: 0 0 calc(10vw * 0.05208333);
}
.network-detail_title > img {
  width: calc(46vw * 0.05208333);
  -webkit-transform: translate(calc(-10vw * 0.05208333), 0);
          transform: translate(calc(-10vw * 0.05208333), 0);
  z-index: -1;
  position: relative;
}
.network-detail_info {
  margin: calc(40vw * 0.05208333) 0 0;
  height: calc(500vw * 0.05208333);
  background: #ffffff;
  box-shadow: 0 calc(4vw * 0.05208333) calc(24vw * 0.05208333) 0 rgba(0, 0, 0, 0.1);
  border-radius: calc(20vw * 0.05208333);
  opacity: 1;
}
.network-tables {
  padding: calc(20vw * 0.05208333) calc(260vw * 0.05208333) calc(40vw * 0.05208333);
  display: flex;
}
.network-tables_left {
  margin: 0 calc(15vw * 0.05208333) 0 0;
  width: calc(50% - calc(15vw * 0.05208333));
}
.network-tables_left .left-title {
  font-size: calc(24vw * 0.05208333);
  font-weight: 600;
  color: #212326;
  padding: 0 calc(40vw * 0.05208333) calc(20vw * 0.05208333);
}
.network-tables_right {
  margin: 0 0 0 calc(15vw * 0.05208333);
  width: calc(50% - calc(15vw * 0.05208333));
}
.network-tables_right .right-title {
  font-size: calc(24vw * 0.05208333);
  font-weight: 600;
  color: #212326;
  padding: 0 calc(40vw * 0.05208333) calc(20vw * 0.05208333);
}
