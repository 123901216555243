.transaction {
  padding: calc(20vw * 0.05208333) 0 0;
}
.transaction > img {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.transaction-header_query {
  padding: calc(20vw * 0.05208333) calc(260vw * 0.05208333) 0;
}
.transaction-table {
  position: relative;
}
.transaction-table > img {
  width: calc(300vw * 0.05208333);
  position: absolute;
  top: calc(100vw * 0.05208333);
  left: 0;
}
.transaction-table_info {
  padding: calc(20vw * 0.05208333) calc(260vw * 0.05208333);
}
.transaction-table_info .info-pagination {
  padding: calc(20vw * 0.05208333) 0 0;
  text-align: right;
}
.transaction .transaction-text_color {
  background: #3d71f6;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}
