.blockInfo {
  padding: calc(20vw * 0.05208333) 0 0;
}
.blockInfo-detail {
  padding: calc(20vw * 0.05208333) calc(260vw * 0.05208333);
}
.blockInfo-detail_title {
  text-align: center;
  font-size: calc(28vw * 0.05208333);
  font-weight: 600;
  margin: 0 0 calc(49vw * 0.05208333);
  position: relative;
}
.blockInfo-detail_title > img {
  width: calc(46vw * 0.05208333);
  -webkit-transform: translate(calc(-10vw * 0.05208333), 0);
          transform: translate(calc(-10vw * 0.05208333), 0);
  z-index: -1;
  position: relative;
}
.blockInfo-detail_title .title-query {
  position: absolute;
  top: 0;
  right: 0;
}
.blockInfo-detail_info {
  background: #ffffff;
  border-radius: calc(20vw * 0.05208333);
  position: relative;
  box-shadow: calc(1vw * 0.05208333) calc(20vw * 0.05208333) calc(20vw * 0.05208333) 0 #f2f2f2;
  border: calc(1vw * 0.05208333) solid #efefef;
  padding: 0 0 calc(20vw * 0.05208333);
}
.blockInfo-detail_info > img {
  position: absolute;
  height: 80%;
  right: 0;
}
.blockInfo-detail_info .info-desc {
  position: relative;
}
.blockInfo-detail_info .info-desc_value {
  padding: 0 calc(52vw * 0.05208333) calc(20vw * 0.05208333);
}
.blockInfo-detail_info .info-desc .flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.blockInfo-detail_info .info-desc_item {
  display: flex;
  padding: calc(20vw * 0.05208333) calc(52vw * 0.05208333) 0;
}
.blockInfo-detail_info .info-desc_item > label {
  display: inline-block;
}
.blockInfo-detail_info .info-desc_item > span {
  color: #959eb6;
}
.blockInfo-abstract {
  padding: calc(20vw * 0.05208333) 0;
}
.blockInfo-abstract_title {
  text-align: center;
  font-size: calc(23vw * 0.05208333);
  font-weight: 600;
  margin: 0 0 calc(37vw * 0.05208333);
}
.blockInfo-abstract_title > img {
  width: calc(46vw * 0.05208333);
  -webkit-transform: translate(calc(-10vw * 0.05208333), 0);
          transform: translate(calc(-10vw * 0.05208333), 0);
  z-index: -1;
  position: relative;
}
.blockInfo-abstract_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(20vw * 0.05208333) calc(260vw * 0.05208333);
  background: url("../../assets/static/abstract_icon.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.blockInfo-abstract_info > img {
  width: calc(300vw * 0.05208333);
  position: absolute;
  top: calc(100vw * 0.05208333);
  left: 0;
}
.blockInfo-abstract_info .info-card {
  text-align: center;
  position: relative;
}
.blockInfo-abstract_info .info-card::after {
  content: "";
  display: block;
  width: calc(1vw * 0.05208333);
  height: calc(88vw * 0.05208333);
  background: #fff;
  position: absolute;
  top: calc(15vw * 0.05208333);
  right: calc(-70vw * 0.05208333);
}
.blockInfo-abstract_info .info-card:last-child::after {
  display: none;
}
.blockInfo-abstract_info .info-card:nth-child(2)::after {
  background: #7782ff;
}
.blockInfo-abstract_info .info-card:nth-child(3)::after {
  background: #ff5196;
}
.blockInfo-abstract_info .info-card:nth-child(4)::after {
  background: #ffb04e;
}
.blockInfo-abstract_info .info-card_img > img {
  width: calc(130vw * 0.05208333);
}
.blockInfo-abstract_info .info-card_value {
  padding: calc(10vw * 0.05208333) 0 0;
  font-weight: 600;
  font-size: calc(35vw * 0.05208333);
  color: #212326;
}
.blockInfo-abstract_info .info-card_label {
  padding: calc(5vw * 0.05208333) 0 0;
}
.blockInfo-abstractNew {
  margin-top: calc(60vw / 19.2);
  padding: calc(20vw / 19.2) calc(50vw / 19.2) calc(31vw / 19.2);
  border-radius: calc(20vw / 19.2);
  border: 1px solid #efefef;
  box-shadow: 1px calc(20vw / 19.2) calc(20vw / 19.2) 0px #f2f2f2;
}
.blockInfo-exchange {
  padding: calc(48vw * 0.05208333) calc(260vw * 0.05208333) calc(40vw * 0.05208333);
}
.blockInfo-exchange_title {
  text-align: left;
  font-size: calc(18vw * 0.05208333);
  font-weight: 600;
  margin: 0 0 calc(37vw * 0.05208333);
  padding: 0 0 0 calc(46vw * 0.05208333);
}
.blockInfo-exchange_title > img {
  width: calc(46vw * 0.05208333);
  -webkit-transform: translate(calc(-10vw * 0.05208333), 0);
          transform: translate(calc(-10vw * 0.05208333), 0);
  z-index: -1;
  position: relative;
}
.blockInfo .block-text_color {
  background: #3d71f6;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}
.blockInfo .info-title {
  padding: calc(25vw * 0.05208333) calc(50vw * 0.05208333) 0;
  font-size: calc(18vw / 19.2);
  line-height: calc(50vw / 19.2);
  font-weight: bold;
  color: #000;
}
