.home {
  padding: calc(20vw * 0.05208333) 0 0;
}
.home-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: calc(50vw * 0.05208333) 0 0 0;
}
.home-header_left {
  width: calc(1017vw * 0.05208333);
}
.home-header_left .left-title {
  margin: 0 0 calc(20vw * 0.05208333);
}
.home-header_left .left-title > P {
  margin: calc(5vw * 0.05208333) 0;
  font-size: calc(52vw * 0.05208333);
  font-weight: 600;
  letter-spacing: calc(1vw * 0.05208333);
  color: #212326;
}
.home-header_left .left-title_color {
  background-image: linear-gradient(to right, #1e80ff, #89b4eb);
  color: transparent;
  -webkit-background-clip: text;
}
.home-header_left .left-subTitle {
  width: calc(704vw * 0.05208333);
  font-size: calc(18vw * 0.05208333);
  color: #797979;
}
.home-header_right {
  position: relative;
}
.home-header_right > img:first-child {
  width: calc(338vw * 0.05208333);
}
.home-header_right > img:last-child {
  width: calc(283vw * 0.05208333);
  position: absolute;
  top: calc(25vw * 0.05208333);
  left: calc(-125vw * 0.05208333);
}
.home-header_center {
  display: none;
  width: 100%;
  text-align: center;
}
.home-header_center > img {
  width: calc(44vw * 0.05208333);
  cursor: pointer;
}
.home-cards {
  padding: calc(75vw * 0.05208333) calc(261vw * 0.05208333) calc(60vw * 0.05208333);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  position: relative;
}
.home-cards > img {
  width: calc(300vw * 0.05208333);
  position: absolute;
  top: calc(200vw * 0.05208333);
  left: calc(100vw * 0.05208333);
}
.home-cards_item {
  padding: calc(16vw * 0.05208333) calc(24vw * 0.05208333);
  margin-bottom: calc(24vw * 0.05208333);
  width: calc(442vw * 0.05208333);
  box-shadow: calc(1vw * 0.05208333) calc(29vw * 0.05208333) calc(20vw * 0.05208333) 0 #f8f8f8;
  border-radius: calc(20vw * 0.05208333);
  opacity: 1;
  border: calc(1vw * 0.05208333) solid #efefef;
  display: inline-flex;
  align-items: center;
  position: relative;
}
.home-cards_item .item-decoration {
  position: absolute;
  top: calc(-20vw * 0.05208333);
  right: calc(10vw * 0.05208333);
  font-size: calc(80vw * 0.05208333);
  color: #f7f7f799;
  font-weight: 600;
}
.home-cards_item .item-icon {
  width: calc(100vw * 0.05208333);
  height: calc(100vw * 0.05208333);
  margin: 0 calc(27vw * 0.05208333) 0 0;
}
.home-cards_item .item-icon > img {
  position: relative;
  top: calc(6vw * 0.05208333);
  width: 100%;
}
.home-cards_item .item-desc_name {
  font-weight: 600;
  font-size: calc(32vw * 0.05208333);
  line-height: calc(41vw * 0.05208333);
}
.home-cards_item .item-desc_title {
  margin-top: calc(14vw * 0.05208333);
  font-size: calc(22vw * 0.05208333);
  line-height: calc(35vw * 0.05208333);
  color: #797979;
}
.home-blocks {
  padding: 0 calc(240vw * 0.05208333) calc(64vw * 0.05208333);
}
.home-blocks_title {
  text-align: center;
  font-size: calc(28vw * 0.05208333);
  font-weight: 600;
  margin: 0 0 calc(37vw * 0.05208333);
  position: relative;
}
.home-blocks_title > img {
  width: calc(46vw * 0.05208333);
  -webkit-transform: translate(calc(-10vw * 0.05208333), 0);
          transform: translate(calc(-10vw * 0.05208333), 0);
  z-index: -1;
  position: relative;
  display: none;
}
.home-blocks_title .title-search {
  display: none;
  position: absolute;
  top: 0;
  right: calc(20vw * 0.05208333);
  text-align: left;
}
.home-blocks_info {
  display: flex;
  justify-content: center;
}
.home-blocks_info .info-item {
  margin: calc(10vw * 0.05208333) calc(20vw * 0.05208333);
  width: calc(437vw * 0.05208333);
}
.home-blocks_info .info-item_card {
  padding: calc(20vw * 0.05208333);
  background-color: #fafafa;
  box-shadow: 0 calc(10vw * 0.05208333) calc(16vw * 0.05208333) 0 #e8e8e8;
  border-radius: calc(14vw * 0.05208333) calc(14vw * 0.05208333) calc(14vw * 0.05208333) calc(14vw * 0.05208333);
  opacity: 1;
  border: calc(1vw * 0.05208333) solid #efefef;
}
.home-blocks_info .info-item_card .card-height,
.home-blocks_info .info-item_card .card-hash {
  padding: 0 0 calc(4vw * 0.05208333);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: calc(16vw * 0.05208333);
  line-height: calc(37vw * 0.05208333);
}
.home-blocks_info .info-item_card .card-height > label,
.home-blocks_info .info-item_card .card-hash > label {
  color: #797979;
}
.home-blocks_info .info-item_card .card-time {
  font-size: calc(16vw * 0.05208333);
  line-height: calc(37vw * 0.05208333);
}
.home-blocks_info .info-item_card .card-time > label {
  color: #797979;
}
.home-blocks_info .info-item_link {
  border-radius: calc(36vw * 0.05208333);
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: calc(5vw * 0.05208333) 0 0;
  padding: calc(7vw * 0.05208333) calc(20vw * 0.05208333);
  background-color: #fff;
  cursor: pointer;
  color: #333;
  font-size: calc(16vw * 0.05208333);
  box-shadow: 0 calc(10vw * 0.05208333) calc(16vw * 0.05208333) 0 #e8e8e8;
}
.home-blocks_info .info-item_link .link-left {
  font-size: calc(15vw * 0.05208333);
  line-height: calc(32vw * 0.05208333);
  color: #3d71f6;
}
.home-blocks_info .info-item_link .link-right > img {
  width: calc(25vw * 0.05208333);
}
.home-traffic {
  position: relative;
  padding: calc(50vw * 0.05208333) calc(260vw * 0.05208333);
  background: #f8f9fd;
}
.home-traffic_title {
  text-align: center;
  font-size: calc(28vw * 0.05208333);
  font-weight: 600;
  margin: 0 0 calc(37vw * 0.05208333);
}
.home-traffic_title > img {
  width: calc(46vw * 0.05208333);
  -webkit-transform: translate(calc(-10vw * 0.05208333), 0);
          transform: translate(calc(-10vw * 0.05208333), 0);
  z-index: -1;
  position: relative;
  display: none;
}
.home-statistics {
  padding: calc(0vw * 0.05208333) 0 calc(75vw * 0.05208333);
  background: url("../../assets/static/ripple_icon.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.home-statistics_title {
  text-align: center;
  font-size: calc(28vw * 0.05208333);
  font-weight: 600;
  margin: 0 0 calc(37vw * 0.05208333);
}
.home-statistics_title > img {
  width: calc(46vw * 0.05208333);
  -webkit-transform: translate(calc(-10vw * 0.05208333), 0);
          transform: translate(calc(-10vw * 0.05208333), 0);
  z-index: -1;
  position: relative;
  display: none;
}
.home-statistics_info {
  display: flex;
  justify-content: center;
}
.home-statistics_info .info-echart {
  width: calc(690vw * 0.05208333);
  padding: calc(36vw * 0.05208333) calc(30vw * 0.05208333) calc(20vw * 0.05208333);
  margin-right: calc(16vw * 0.05208333);
  background: #ffffff;
  box-shadow: calc(1vw * 0.05208333) calc(29vw * 0.05208333) calc(20vw * 0.05208333) 0 #f2f2f2;
  border-radius: calc(20vw * 0.05208333);
  opacity: 1;
  border: 1px solid #efefef;
}
.home-statistics_info .info-echart_title {
  font-size: calc(24vw * 0.05208333);
  line-height: calc(29vw * 0.05208333);
  color: #212326;
}
.home-statistics_info .info-echart:last-child {
  margin-right: 0;
}
.home .table-columns_xh {
  width: calc(28vw * 0.05208333);
  height: calc(28vw * 0.05208333);
  text-align: center;
  line-height: calc(28vw * 0.05208333);
  color: #212326;
}
.home .table-columns_hash {
  color: #3d71f6;
}
.home :where(.css-dev-only-do-not-override-9mvw6q)[class^="ant-table"] [class^="ant-table"]::before {
  display: none !important;
}
.home .status-color_success {
  color: #3eba59;
}
.home .status-color_fail {
  color: #ff3636;
}
.home .block-color {
  color: #7782ff;
}
