.config-modal .modal-detail_item .item-label {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  padding: calc(20vw * 0.05208333) 0 calc(10vw * 0.05208333);
  border-bottom: calc(2vw * 0.05208333) solid #28356b;
}
.config-modal .modal-tables {
  display: flex;
  flex-wrap: wrap;
  padding: calc(20vw * 0.05208333) 0;
}
.config-modal .modal-tables_left {
  width: calc(45% - calc(10vw * 0.05208333));
  margin: 0 calc(10vw * 0.05208333) 0 0;
}
.config-modal .modal-tables_right {
  width: calc(55% - calc(10vw * 0.05208333));
  margin: 0 0 0 calc(5vw * 0.05208333);
}
.config-modal .modal-tables_pagination {
  width: 100%;
  padding: calc(20vw * 0.05208333) 0 0;
  text-align: right;
}
