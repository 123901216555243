.monitor {
  padding: calc(20vw * 0.05208333) 0 0;
}
.monitor-header {
  padding: calc(50vw * 0.05208333) calc(150vw * 0.05208333) calc(121vw * 0.05208333);
}
.monitor-header_card {
  display: flex;
  align-items: center;
  background: #263238;
  border-radius: calc(30vw * 0.05208333);
  opacity: 1;
  padding: calc(40vw * 0.05208333) 0;
  color: #fff;
}
.monitor-header_card .card-info {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.monitor-header_card .card-info_item {
  width: calc(calc(100% / 5) - calc(40vw * 0.05208333));
  margin: 0 calc(20vw * 0.05208333);
  position: relative;
}
.monitor-header_card .card-info_item::after {
  content: "";
  display: block;
  width: calc(1vw * 0.05208333);
  height: calc(88vw * 0.05208333);
  background: #fff;
  position: absolute;
  top: 0;
  right: calc(-21vw * 0.05208333);
}
.monitor-header_card .card-info_item:last-child::after {
  display: none;
}
.monitor-header_card .card-info_item:nth-child(1)::after {
  background: #7782ff;
}
.monitor-header_card .card-info_item:nth-child(2)::after {
  background: #ff5196;
}
.monitor-header_card .card-info_item:nth-child(3)::after {
  background: #ffb04e;
}
.monitor-header_card .card-info_item:nth-child(4)::after {
  background: #27caea;
}
.monitor-header_card .card-info_item:nth-child(5)::after {
  background: #2746ea;
}
.monitor-header_card .card-info_item > p {
  margin: 0 0 calc(10vw * 0.05208333);
}
.monitor-header_card .card-info_item > p:first-child {
  font-size: calc(50vw * 0.05208333);
  font-weight: 600;
}
.monitor-header_card .card-info_item > p:last-child {
  font-size: calc(18vw * 0.05208333);
  letter-spacing: calc(1vw * 0.05208333);
}
.monitor-header_card .card-info_btn {
  margin: 0 calc(50vw * 0.05208333);
  width: calc(150vw * 0.05208333);
  height: calc(60vw * 0.05208333);
  line-height: calc(60vw * 0.05208333);
  background: linear-gradient(132deg, #a1fbff 10%, #ccd0ff 55%, #ffcce0 100%);
  border-radius: calc(7vw * 0.05208333);
  opacity: 1;
  color: #333;
  cursor: pointer;
}
.monitor-channel {
  padding: calc(20vw * 0.05208333) calc(150vw * 0.05208333) calc(86vw * 0.05208333);
  position: relative;
}
.monitor-channel > img {
  width: calc(300vw * 0.05208333);
  position: absolute;
  top: calc(200vw * 0.05208333);
  left: calc(100vw * 0.05208333);
}
.monitor-channel_title {
  text-align: center;
  font-size: calc(34vw * 0.05208333);
  font-weight: 600;
  margin: 0 0 calc(61vw * 0.05208333);
  position: relative;
}
.monitor-channel_title > img {
  width: calc(46vw * 0.05208333);
  -webkit-transform: translate(calc(-10vw * 0.05208333), 0);
          transform: translate(calc(-10vw * 0.05208333), 0);
  z-index: -1;
  position: relative;
}
.monitor-channel_title .title-search {
  position: absolute;
  top: 0;
  right: calc(20vw * 0.05208333);
  text-align: left;
}
.monitor-channel_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.monitor-channel_card .card-item {
  margin: calc(10vw * 0.05208333) calc(20vw * 0.05208333);
}
.monitor-channel_card .card-item_img > img {
  width: calc(150vw * 0.05208333);
}
.monitor-channel_card .card-item_desc {
  margin: calc(30vw * 0.05208333) 0 0;
}
.monitor-channel_card .card-item_desc .desc-label {
  font-size: calc(50vw * 0.05208333);
  font-weight: 600;
}
.monitor-channel_card .card-item_desc .desc-label_unit {
  font-size: calc(18vw * 0.05208333);
  font-weight: 500;
}
.monitor-channel_card .card-item_desc .desc-name {
  padding: calc(10vw * 0.05208333) 0 0;
  color: #797979;
}
.monitor-statistics {
  padding: calc(20vw * 0.05208333) calc(150vw * 0.05208333) calc(40vw * 0.05208333);
  background: url("../../assets/static/ripple_icon.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.monitor-statistics_title {
  text-align: center;
  font-size: calc(34vw * 0.05208333);
  font-weight: 600;
  margin: 0 0 calc(37vw * 0.05208333);
}
.monitor-statistics_title > img {
  width: calc(46vw * 0.05208333);
  -webkit-transform: translate(calc(-10vw * 0.05208333), 0);
          transform: translate(calc(-10vw * 0.05208333), 0);
  z-index: -1;
  position: relative;
}
.monitor-statistics_info {
  display: flex;
  justify-content: space-between;
}
.monitor-statistics_info .info-echart {
  margin: calc(10vw * 0.05208333) calc(20vw * 0.05208333);
  width: calc(calc(100% / 3) - calc(40vw * 0.05208333));
  padding: calc(20vw * 0.05208333);
  background: #ffffff;
  box-shadow: calc(1vw * 0.05208333) calc(29vw * 0.05208333) calc(20vw * 0.05208333) 0 #f2f2f2;
  border-radius: calc(30vw * 0.05208333);
  opacity: 1;
  border: calc(1vw * 0.05208333) solid #efefef;
}
.monitor-statistics_info .info-echart_title {
  text-align: center;
  color: #3485ff;
  font-size: calc(24vw * 0.05208333);
  padding: calc(16vw * 0.05208333) 0 0;
}
