.admin ::-webkit-scrollbar {
  display: none;
}
.admin-layout {
  background-color: #fff;
}
.admin-layout_background {
  position: relative;
}
.admin-layout_background .background-left {
  position: absolute;
  top: 0;
  left: 0;
}
.admin-layout_background .background-left_top {
  position: relative;
  left: calc(47vw * 0.05208333);
  top: calc(53vw * 0.05208333);
  width: calc(536vw * 0.05208333);
}
.admin-layout_background .background-left_bottom {
  width: calc(380vw * 0.05208333);
  -webkit-transform: translate(calc(-300vw * 0.05208333), calc(50vw * 0.05208333));
          transform: translate(calc(-300vw * 0.05208333), calc(50vw * 0.05208333));
}
.admin-layout_background .background-right {
  position: absolute;
  top: 0;
  right: 0;
}
.admin-layout_background .background-right_top {
  width: calc(568vw * 0.05208333);
  -webkit-transform: translate(calc(719vw * 0.05208333), calc(0vw * 0.05208333));
          transform: translate(calc(719vw * 0.05208333), calc(0vw * 0.05208333));
}
.admin-layout_background .background-right_bottom {
  width: calc(568vw * 0.05208333);
}
.admin-layout_header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: calc(20vw * 0.05208333) calc(150vw * 0.05208333);
  height: calc(80vw * 0.05208333);
  z-index: 1;
  background: #fff;
}
.admin-layout_header .header-vwpty {
  width: 100%;
  height: calc(20vw * 0.05208333);
}
.admin-layout_header .header-logo {
  line-height: 1;
}
.admin-layout_header .header-logo > img {
  width: calc(134vw * 0.05208333);
}
.admin-layout_header .header-logo_title {
  padding: 0 0 0 calc(10vw * 0.05208333);
  font-weight: 600;
  vertical-align: calc(15vw * 0.05208333);
  font-size: calc(30vw * 0.05208333);
}
.admin-layout_header .header-nav :global .active {
  height: 100%;
  position: relative;
}
.admin-layout_header .header-nav :global .active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: calc(6vw * 0.05208333);
  width: calc(40vw * 0.05208333);
  left: calc(50% - calc(20vw * 0.05208333));
  height: calc(10vw * 0.05208333);
  background: url("../static/active_icon.png") no-repeat;
  background-size: 100%;
}
.admin-layout_header .header-nav > a {
  display: inline-block;
  margin: 0 calc(20vw * 0.05208333);
  color: #333;
  font-size: calc(18vw * 0.05208333);
}
.admin-layout_header .header-btns {
  font-size: calc(18vw * 0.05208333);
}
.admin-layout_header .header-btns_one {
  padding: 0 calc(26vw * 0.05208333);
  height: calc(50vw * 0.05208333);
  border: 1px solid #333;
}
.admin-layout_header .header-btns_one > img {
  width: calc(14vw * 0.05208333);
  margin: 0 0 0 calc(22vw * 0.05208333);
  vertical-align: calc(-1vw * 0.05208333);
}
.admin-layout_header .header-btns_two {
  padding: 0 calc(30vw * 0.05208333);
  height: calc(40vw * 0.05208333);
}
.admin-layout_content {
  padding: calc(20vw * 0.05208333) 0 0;
  min-height: calc(100vh - calc(130vw * 0.05208333));
  z-index: 1;
  position: relative;
}
.admin-layout_footer {
  height: calc(50vw * 0.05208333);
  padding: 0;
  text-align: center;
  line-height: 1.6;
  background: #292930 !important;
  position: relative;
  z-index: 1;
}
.admin-layout_footer .footer-tip {
  color: #ffffff;
  line-height: calc(25vw * 0.05208333);
  opacity: 0.8;
  padding: 10px 0 0;
}
.login {
  height: 100vh;
  background: #f7f8fa;
  position: relative;
}
.login-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
}
.login-container_left {
  display: inline-block;
  width: calc(835vw * 0.05208333);
  height: 100%;
  background: url("../static/loginleft_img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.login-container_left .left-main_header {
  padding: calc(70vw * 0.05208333) 0 calc(67vw * 0.05208333) calc(58vw * 0.05208333);
  font-weight: 600;
  font-size: calc(18vw * 0.05208333);
}
.login-container_left .left-main_header > img {
  width: calc(134vw * 0.05208333);
  vertical-align: middle;
}
.login-container_left .left-main_header > label {
  color: #212326d6;
  padding: 0 calc(30vw * 0.05208333) 0 calc(10vw * 0.05208333);
  position: relative;
  font-size: calc(26vw * 0.05208333);
}
.login-container_left .left-main_header > label::after {
  display: none;
  position: absolute;
  content: "";
  top: calc(-3vw * 0.05208333);
  width: 1px;
  height: calc(45vw * 0.05208333);
  right: calc(2vw * 0.05208333);
  background: #000;
}
.login-container_left .left-main_header > span {
  color: #212326d6;
  padding: 0 0 0 calc(20vw * 0.05208333);
  font-size: calc(28vw * 0.05208333);
}
.login-container_left .left-main_desc {
  padding: 0 0 0 calc(68vw * 0.05208333);
  color: #212326d6;
}
.login-container_left .left-main_desc > p {
  font-size: calc(25vw * 0.05208333);
  font-weight: 600;
  margin: 0 0 calc(15vw * 0.05208333);
  font-size: calc(44vw * 0.05208333);
}
.login-container_left .left-main_desc > p > span {
  background-image: linear-gradient(to right, #1e80ff, #89b4eb);
  color: transparent;
  -webkit-background-clip: text;
}
.login-container_right {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - calc(835vw * 0.05208333));
  height: 100%;
  position: relative;
}
.login-container_right::before {
  content: "";
  display: block;
  position: absolute;
  width: calc(478vw * 0.05208333);
  height: calc(224vw * 0.05208333);
  background: url("../static/loginleft_icon.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-x: calc(-112vw * 0.05208333);
}
.login-container_right::after {
  content: "";
  display: block;
  position: absolute;
  width: calc(321vw * 0.05208333);
  height: calc(685vw * 0.05208333);
  background: url("../static/loginright_icon.png");
  right: 0;
  background-size: 100%;
  background-repeat: no-repeat;
}
.login-container_right .right-main {
  position: absolute;
  top: calc(50% - calc(250vw * 0.05208333));
  right: calc(300vw * 0.05208333);
}
.login-container_right .right-main_header {
  padding: 0 calc(20vw * 0.05208333) calc(30vw * 0.05208333);
}
.login-container_right .right-main_header > label {
  font-weight: 600;
  color: #212326d6;
  font-size: calc(34vw * 0.05208333);
  padding: 0 calc(24vw * 0.05208333) 0 0;
}
.login-container_right .right-main_header > span {
  color: #4e5969;
  font-size: calc(20vw * 0.05208333);
}
.login-container_right .right-main_middle {
  width: calc(500vw * 0.05208333);
  padding-bottom: calc(70vw * 0.05208333);
  background: url("../static/logincenter_img.png");
  background-repeat: no-repeat;
  background-size: 106% 103%;
  background-position: bottom;
}
.login-container_right .right-main_middle .middle-header {
  font-weight: 600;
  padding: calc(32vw * 0.05208333) 0 calc(10vw * 0.05208333);
  margin: 0 calc(65vw * 0.05208333);
  color: #212326d6;
  border-bottom: 1px solid #d8d8d8;
  position: relative;
  font-size: calc(20vw * 0.05208333);
}
.login-container_right .right-main_middle .middle-header::after {
  content: "";
  display: block;
  position: absolute;
  width: calc(95vw * 0.05208333);
  height: calc(2vw * 0.05208333);
  background: #38c585;
  bottom: calc(-2vw * 0.05208333);
}
.login-container_right .right-main_middle .middle-form {
  padding: calc(65vw * 0.05208333) calc(65vw * 0.05208333) 0;
}
.login-container_right .right-main_middle .middle-form_wrapper {
  padding: 0 0 calc(41vw * 0.05208333);
}
.login-container_right .right-main_middle .middle-form_wrapper > input {
  height: calc(48vw * 0.05208333);
  background: #dddddd26;
}
.login-container_right .right-main_middle .middle-form_wrapper > span {
  height: calc(48vw * 0.05208333);
  background: #dddddd26;
}
.login-container_right .right-main_middle .middle-form_wrapper:nth-child(2) {
  padding: 0 0 calc(24vw * 0.05208333);
}
.login-container_right .right-main_middle .middle-btn {
  padding: 0px calc(60vw * 0.05208333);
}
.login-container_right .right-main_middle .middle-btn > button {
  height: calc(48vw * 0.05208333);
}
:global .ant-table-wrapper table {
  box-shadow: 0 calc(4vw * 0.05208333) calc(24vw * 0.05208333) 0 rgba(0, 0, 0, 0.1);
  opacity: 1;
}
:global .ant-table-wrapper table .ant-table-tbody tr:nth-child(2n + 1) {
  background: #f5f5f5;
}
:global .ant-table-wrapper table .ant-table-tbody tr:nth-child(2n) {
  background: #fafafa;
}
:global .ant-table-wrapper table .ant-table-tbody tr > td {
  border: none;
}
:global .ant-table-wrapper table .ant-table-thead tr:not(:first-child) > th {
  background: #fff;
  color: #333;
}
:global .ant-tag-green {
  color: #38c585;
  background: #e3f9e9;
  border-color: #38c585;
}
:global .ant-pagination .ant-pagination-options-quick-jumper {
  padding: 0 calc(10vw * 0.05208333);
  border-radius: calc(3vw * 0.05208333);
  background: #959eb6;
}
:global .ant-pagination .ant-pagination-options-quick-jumper > input {
  height: calc(25vw * 0.05208333);
  width: calc(40vw * 0.05208333);
}
:global .ant-pagination-item {
  background-color: #fff !important;
  border: calc(1vw * 0.05208333) solid #dcdcdc !important;
}
:global .ant-pagination-item-active {
  background: #3d71f6 !important;
  border: none;
}
:global .ant-pagination-item-active > a {
  color: #fff !important;
}
