.block {
  padding: calc(20vw * 0.05208333) 0 0;
}
.block > img {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.block-header_query {
  padding: calc(20vw * 0.05208333) calc(260vw * 0.05208333) 0;
}
.block-table {
  position: relative;
}
.block-table > img {
  width: calc(300vw * 0.05208333);
  position: absolute;
  top: calc(230vw * 0.05208333);
  left: 0;
}
.block-table_info {
  padding: calc(20vw * 0.05208333) calc(260vw * 0.05208333);
}
.block-table_info .info-pagination {
  padding: calc(20vw * 0.05208333) 0 0;
  text-align: right;
}
.block .block-text_color {
  background: #3D71F6;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}
