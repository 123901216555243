.config-topology {
  height: 100%;
  position: relative;
}
.config-topology_title {
  position: absolute;
  top: calc(20vw * 0.05208333);
  left: calc(40vw * 0.05208333);
  color: #212326;
  font-size: calc(24vw * 0.05208333);
  font-weight: 600;
}
.config-topology_tip {
  height: calc(50vw * 0.05208333);
  display: flex;
  justify-content: flex-end;
  padding: 0 calc(40vw * 0.05208333);
}
.config-topology_tip .tip-item {
  height: 100%;
  line-height: calc(50vw * 0.05208333);
  padding: 0 calc(20vw * 0.05208333);
  font-size: calc(13vw * 0.05208333);
}
.config-topology_tip .tip-item > img {
  height: calc(35vw * 0.05208333);
  vertical-align: middle;
}
.config-topology_id {
  height: calc(100% - calc(50vw * 0.05208333));
}
