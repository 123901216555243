.config-desc {
  display: flex;
  flex-wrap: wrap;
  padding: calc(20vw * 0.05208333) 0 0;
  word-wrap: break-word;
  word-break: break-all;
}
.config-desc .desc-label {
  display: inline-block;
  width: calc(120vw * 0.05208333);
}
.config-desc .desc-value {
  color: #959eb6;
}
.config-desc .gutter-4 {
  width: calc(144vw * 0.05208333);
}
.config-desc .gutter-8 {
  width: calc(200vw * 0.05208333);
}
.config-desc .desc-list {
  margin: calc(10vw * 0.05208333) 0 0;
  width: 100%;
  box-shadow: calc(1vw * 0.05208333) calc(20vw * 0.05208333) calc(20vw * 0.05208333) 0 #f2f2f2;
  border-radius: calc(20vw * 0.05208333) calc(20vw * 0.05208333) 0 0;
  background: #263238;
  border: calc(1vw * 0.05208333) solid #efefef;
  color: #fff;
}
.config-desc .desc-list_header {
  display: flex;
  justify-content: space-between;
  padding: calc(10vw * 0.05208333) calc(20vw * 0.05208333);
}
.config-desc .desc-list_header > p {
  margin: 0;
}
.config-desc .desc-list_header > p:last-child {
  cursor: pointer;
}
.config-desc .desc-list_content {
  padding: calc(20vw * 0.05208333);
  background: #ffffff;
  border: calc(1vw * 0.05208333) solid #cfd5de;
  overflow: auto;
}
.config-desc .desc-table {
  width: 100%;
}
